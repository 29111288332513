@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');

$primary-color: #ffb000;
$background-color: #222;

body {
  margin: 20px;
  font-family: 'Source Code Pro', Menlo, Monaco, Consolas, 'Courier New', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $primary_color;
  background-color: $background-color;
}

h1 {
  margin-top: 0;
}

.green {
  color: #33ff00;
}

.amber {
  color: $primary_color
}

a:link,
a:hover,
a:active,
a:visited {
  color: $primary_color;
}

h1 a:link,
h1 a:hover,
h1 a:active,
h1 a:visited {
  text-decoration: none;
}

.uploaded-image {
  margin: 10px 0;
  border: 1px solid $primary_color;
}

.crisp-zoom {
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-crisp-edges;
  image-rendering: pixelated;
  image-rendering: crisp-edges;
  vertical-align: bottom;
}

.processed-sprite {
  border: 1px solid  $primary_color;
  border-collapse: collapse;
  display: inline-block;
}

button.button-link {
    background: none!important;
    border: none;
    padding: 0!important;
    font: inherit;
    color: $primary_color;
    text-decoration: underline;
    cursor: pointer;
}

button.button-link:hover {
  text-decoration: none;
}

.form-fields {
  display: table;
}

.form-field {
  display: table-row;
}

.form-field label,
.form-field input {
  display: table-cell;
  padding: 3px 10px;
}

.form-field input[type=checkbox] {
  margin-left: -1px;
}

.form-field input[type=file] {
  margin-left: -10px;
}

.Modal {
  background-color:  $background-color;
}